export enum AccountTypeEnum {
  NotSet,
  InsuranceCompany,
  Rental,
  Lease,
  Fleet,
  OneTime,
  Other,
  Returning,
  Ngc,
}
