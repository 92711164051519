import { Pipe, PipeTransform } from '@angular/core';
import { Permission as Perm } from '@hrz/core/models/enums/permission.enum';
import { AuthService } from '@hrz/core/services/auth.service';

@Pipe({
  name: 'auth',
})
export class AuthorizePipe implements PipeTransform {
  constructor(private auth: AuthService) {}

  transform(permission: Perm): boolean {
    const hasAccess = this.auth.userHasScope(permission);
    console.log('AuthorizePipe: Checking access', permission, hasAccess);

    return hasAccess;
  }
}
