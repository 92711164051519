import { animate, query, sequence, stagger, style, transition, trigger } from '@angular/animations';

export const staggerCardList = trigger('staggerCardList', [
  transition('* => *', [
    query(':enter', style({ opacity: 0, height: 0 }), { optional: true }),
    query(
      ':enter',
      stagger('125ms', [
        style({
          opacity: 0,
          height: 0,
          width: 0,
          paddingTop: 0,
          paddingBottom: 0,
          overflow: 'hidden',
        }),
        sequence([
          animate(
            '.15s ease',
            style({
              opacity: 0,
              transform: 'translateX(-30px)',
              height: '*',
              width: '*',
              paddingTop: '*',
              paddingBottom: '*',
              overflow: 'hidden',
            })
          ),
          animate('.15s ease', style({ opacity: 1, transform: 'translateX(0)', overflow: '*' })),
        ]),
      ]),
      { optional: true }
    ),
  ]),
]);

export const toastList = trigger('toastList', [
  transition('* => *', [
    query(':enter', style({ opacity: 0, height: 0 }), { optional: true }),

    query(
      ':enter',
      stagger('125ms', [
        style({
          opacity: 0,
          height: 0,
          transform: 'translateY(100px)',
          overflow: 'hidden',
        }),
        sequence([animate('.5s ease', style({ opacity: 1, height: '*', transform: 'translateY(0)', overflow: 'hidden' }))]),
      ]),
      { optional: true }
    ),

    query(
      ':leave',
      stagger('0ms', [
        style({
          opacity: 1,
          height: '*',
          overflow: 'hidden',
        }),
        sequence([animate('.5s ease', style({ opacity: 0, height: 0, overflow: 'hidden' }))]),
      ]),
      { optional: true }
    ),
  ]),
]);
