import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { interval, Subject, Subscription } from 'rxjs';
import { SimpleNotification, ToastOption } from '@hrz/core/models/simple-notification';

@Component({
  selector: 'hrz-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit, OnDestroy {
  @Input() toast: SimpleNotification;
  @Input() options: ToastOption;

  @Output() hidden: Subject<boolean> = new Subject();

  private subscription: Subscription;
  timeLeft: number;
  percentLeft = 100;

  private _refreshInterval = 100;

  constructor() {}

  ngOnInit(): void {
    if (!this.toast.options.delay) {
      this.toast.options.delay = 3500;
    }

    this.timeLeft = this.toast.options.delay;
    this.startInterval();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  startInterval() {
    this.subscription = interval(this._refreshInterval).subscribe(_ => {
      this.timeLeft -= this._refreshInterval;
      this.percentLeft = Math.round((this.timeLeft * 100) / this.toast.options.delay);
      if (this.timeLeft <= 0) {
        this.subscription.unsubscribe();

        setTimeout(() => {
          this.hide();
        }, this._refreshInterval);
      }
    });
  }

  setFocus() {
    // Reset time left
    this.timeLeft = this.toast.options.delay;
    this.percentLeft = 100;
    this.subscription.unsubscribe();
  }

  setBlur() {
    this.startInterval();
  }

  hide() {
    this.hidden.next(true);
  }
}
