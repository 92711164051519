export enum CountryEnum {
  Austria = 19,
  Belgium = 20,
  Bulgaria = 21,
  Croatia = 22,
  Cyprus = 23,
  CzechRepublic_1 = 24,
  CzechRepublic_2 = 25,
  Denmark = 26,
  Estonia = 1,
  Finland = 27,
  France = 28,
  Germany = 30,
  Gibraltar = 29,
  Greece = 3,
  Hungary = 4,
  Iceland = 31,
  Ireland = 32,
  Italy = 5,
  Latvia = 33,
  Liechtenstein = 34,
  Lithuania = 6,
  LucasLand = 42,
  Luxembourg = 35,
  Malta = 36,
  Netherlands = 7,
  Norway = 8,
  Poland = 9,
  Portugal = 10,
  Romania = 37,
  SanMarino = 44,
  Slovakia = 38,
  Slovenia = 39,
  Spain = 2,
  Sweden = 40,
  Test_1 = 16,
  Test_2 = 43,
  UnitedKingdom = 41,

  NotDefined = 0,
}
