import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@hrz/core/services/auth.service';
import { EnumService } from '@hrz/core/services/enum.service';
import { Enum } from '@hrz/core/models/enum';

@Pipe({
  name: 'moneyDisplay',
})
export class MoneyDisplayPipe implements PipeTransform {
  private tenantId = 0;

  constructor(
    private auth: AuthService,
    private enumService: EnumService
  ) {
    this.auth
      .getTenantId()
      .subscribe(result => (this.tenantId = result))
      .unsubscribe();
  }

  transform(property: number, _mode: string): any {
    // \u00A0 = non-breaking space
    const currency = this.enumService.getCurrency(this.tenantId);
    const localCurrency = currency.find(x => x.Key == 'currency');
    const localAlignment = currency.find(x => x.Key == 'align');

    // var formatted =
    const formatted = this.defaultAlignment(localAlignment) ? `${localCurrency.Value}\u00A00,00` : `\u00A00,00 ${localCurrency.Value}`;
    if (!property || isNaN(property)) { return formatted; }

    if (Number(property) > 0) {
      return this.defaultAlignment(localAlignment)
        ? `${localCurrency.Value}\u00A0` + Number(property).toFixed(2).replace('.', ',')
        : `\u00A0` + Number(property).toFixed(2).replace('.', ',') + ` ${localCurrency.Value}`;
    }
    return formatted;
  }

  private defaultAlignment(localAlignment: Enum): boolean {
    return localAlignment.Value === 'L';
  }
}
