import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '@hrz/env';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppInsightsService {

  public appInsights: ApplicationInsights;
  private isAppInsightsEnabled: boolean;
  
  constructor() {
    this.isAppInsightsEnabled = environment.appInsights.enable;
    if (this.isAppInsightsEnabled) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: environment.appInsights.instrumentationKey,
          enableAutoRouteTracking: true, // option to log all route changes
        },
      });
      this.appInsights.loadAppInsights();
    }
  }

  logPageView(name?: string, url?: string) {
    if (this.isAppInsightsEnabled) {
      this.appInsights.trackPageView({
        name: name,
        uri: url,
      });
    } 
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (this.isAppInsightsEnabled) {
      this.appInsights.trackEvent({ name: name }, properties);
    }
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    if (this.isAppInsightsEnabled) {
      this.appInsights.trackMetric({ name: name, average: average }, properties);
    }
  }

  logException(exception: Error, severityLevel?: number) {
    if (this.isAppInsightsEnabled) {
      this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (this.isAppInsightsEnabled) {
      this.appInsights.trackTrace({ message: message }, properties);
    }
    console.log(message);
  }
}
