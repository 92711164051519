import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hrz-switch-version',
  templateUrl: './switch-version.component.html',
  styleUrls: ['./switch-version.component.scss'],
})
export class SwitchVersionComponent implements OnInit {
  public readonly isDisplayed = true; // Set to false to disable the switch
  private readonly currentVersion = 'v6-legacy';
  private readonly newVersion = 'v15';
  isNewVersion: boolean;
  srcImgDark = '../../assets/img/Horizons-Dark-Blue.svg';
  srcImgLight = '../../assets/img/Horizons-Light-Grey.svg';

  ngOnInit(): void {
    this.isNewVersion = window.location.host.includes(this.newVersion);
  }

  onToggleChange(): void {
    const host = window.location.host;
    window.location.href =
      window.location.protocol +
      '//' +
      (this.isNewVersion ? host.replace(this.newVersion, this.currentVersion) : host.replace(this.currentVersion, this.newVersion));
  }
}
