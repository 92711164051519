import { AfterViewInit, Component, HostListener } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'hrz-dashboard',
  templateUrl: './full-layout.component.html',
  styleUrls: ["./full-layout.component.scss"],
  animations: [
    trigger('expandSidebar', [
      state('collapsed', style({  width: '300px' })),
      state('expanded', style({ width: '70px'})),
      transition('collapsed <=> expanded', animate('250ms ease-in-out')),
    ])
  ]})

export class FullLayoutComponent implements AfterViewInit {
  expanded = true;
  isResponsive = false;
  
  @HostListener('window:resize', ['$event'])
  @HostListener('window:orientationchange', ['$event'])
  onResize() {
    this.isResponsive = this.isResponsiveMode();
  }

  ngAfterViewInit() : void {
    this.isResponsive = this.isResponsiveMode()
  }

  toggleSidebar(): void {
    this.expanded = !this.expanded;
  }

  private isResponsiveMode() {
    return (window.innerWidth <= 768)
  }
}
