import { Injectable } from '@angular/core';
import { environment } from '@hrz/env';
import { AariConfig } from '@hrz/core/models/aari/aari-config.model';
import { AuthService } from '@hrz/core/services/auth.service';
import { CountryEnum } from '@hrz/core/enums/country.enum';
import { Dossier } from '@hrz/core/models/dossier';

/**
 * Provides methods for interacting with the Aari agenda.
 */
@Injectable({
  providedIn: 'root',
})
export class AariService {
  constructor(private authService: AuthService) {
  }

  /**
   * Returns the URL for the Aari agenda.
   *
   * @returns {string} The Aari agenda URL.
   */
  public getAariAgendaUrl(): string {
    return this.aariConfig.agendaBaseUrl;
  }

  /**
   * Returns the URL for the Aari agenda for a specific dossier.
   *
   * @param {string} aariDossierId The ID of the dossier in aari system.
   * @returns {string} The Aari agenda URL for the specified dossier.
   */
  public getAariAgendaDossierUrl(aariDossierId: string): string {
    return `${this.aariConfig.agendaBaseUrl}/planning?dossier=${aariDossierId}`;
  }

  /**
   * Checks if for the given dossier we can access the Aari agenda.
   *
   * @param {Dossier} dossier The dossier object to check.
   * @returns {boolean} True if the dossier can access the Aari agenda, false otherwise.
   */
  public canAccessToAariAgenda(dossier:Dossier): boolean {
    return dossier?.DossierSource === 'AppointmentTool' && !!dossier?.ExternalReference;
  }

  /**
   * Returns the Aari configuration for the current tenant.
   *
   * @returns {AariConfig} The Aari configuration object.
   */
  private get aariConfig(): AariConfig {
    const {aari} = environment;

    const tenantId = this.authService.getTenantIdAsNumber();

    const countryCode = 'fr';

    switch (tenantId) {
      case CountryEnum.France:
        return aari.fr;
      default:
        throw Error(`Unknown aari config for the country: ${countryCode}`);
    }
  }
}
