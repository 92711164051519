import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Address } from '@hrz/core/models/address';

@Pipe({
  name: 'addressDisplay',
})
export class AddressDisplayPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(address: Address, display: number): any {
    let formatted = this.translateService.instant('SHARED.PIPES.NOT_AVAILABLE');
    if (!address) { return formatted; }

    if (display === 1) {
      if (address.Street) { formatted = address.Street; }
      if (address.Extra) { formatted = formatted + ' (' + address.Extra + ') '; }
      if (address.Street) { formatted = formatted + '\n'; }
      if (address.ZipCode) { formatted = formatted + address.ZipCode + ' '; }
      if (address.City) { formatted = formatted + address.City + ' '; }
      if (address.CountryName) { formatted = formatted + address.CountryName; }
    } else if (display === 2) {
      if (address.Street) { formatted = address.Street; }
      if (address.Extra) { formatted = formatted + ' (' + address.Extra + ') '; }
      if (address.Street) { formatted = formatted + '\n'; }
      if (address.ZipCode) { formatted = formatted + address.ZipCode + ' '; }
      if (address.City) { formatted = formatted + address.City + ' '; }
      if (address.Region) { formatted = formatted + '(' + address.Region + ') '; }
      if (address.CountryName) { formatted = formatted + address.CountryName; }
    }

    return formatted;
  }
}
