// TODO Imad move to core/models.
export class ToastOption {
  delay ?= 3500;
  className?: string;
}

export class SimpleNotification {
  public header: string;
  public message: string;
  public type: string;
  public options: ToastOption;
  public errors: string[];
}

export enum NotificationType {
  success = 'success',
  error = 'error',
  info = 'info',
  warning = 'warning',
}
