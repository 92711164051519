import { ErrorHandler, Injectable } from '@angular/core';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  constructor(private appInsightsService: AppInsightsService) {
    super();
  }

  handleError(error: Error) {
    console.error(error); // To ensure the error is displayed in the console
    this.appInsightsService.logException(error); // Send the error to Azure AppInsights
  }
}
