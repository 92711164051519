<div class="hrz-toast p-2 text-white" [class.bg-success]="toast.type === 'success'"
[class.bg-warning]="toast.type === 'warning'"
[class.bg-danger]="toast.type === 'error'"
[class.bg-info]="toast.type === 'info'">

    <div (mouseenter)="setFocus()" (mouseleave)="setBlur()">
      <h5>
      <!-- Icon -->
      <i class="fa fa-lg me-3"
      [class.fa-check-circle]="toast.type === 'success'"
      [class.fa-warning]="toast.type === 'warning'"
      [class.fa-times-circle]="toast.type === 'error'"
      [class.fa-info-circle]="toast.type === 'info'"></i>
      
      <!-- Message -->
      {{ toast.header }}</h5>
      <div *ngIf="toast.message" >
        {{ toast.message }}
      </div>
      <ul>
        <li *ngFor="let error of toast.errors; ">
          {{ error }}
        </li>
      </ul>

      <div class="progress-bar" [style.width.%]="percentLeft"></div>
    </div>

    <div>
      <i class="fa fa-times close-button" aria-hidden="true" (click)="hide()" ></i>
    </div>

</div>

