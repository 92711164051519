import { Injectable, Optional, SkipSelf } from '@angular/core';
import { NotificationType, SimpleNotification, ToastOption } from '@hrz/core/models/simple-notification';
import { ValidationError } from '@hrz/core/models/validation-error';
import { TranslateService } from '@ngx-translate/core';
import { ApiActionResult } from '@hrz/core/models/api-action-result';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  toasts: SimpleNotification[] = [];

  constructor(
    private translateService: TranslateService,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: ToasterService
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('ToasterService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  public showSuccess(message: string, hideAfterMiliseconds: number = 3000) {
    this.show(this.translateService.instant('SHARED.ALERT_MODULE.SUCCESS'), message, NotificationType.success, {
      delay: hideAfterMiliseconds,
    });
  }

  public showInfo(message: string, hideAfterMiliseconds: number = 3000) {
    this.show(this.translateService.instant('SHARED.ALERT_MODULE.INFO'), message, NotificationType.info, { delay: hideAfterMiliseconds });
  }

  /**
   * Displays warnings according to the information provided as input:
   *  - If only one message is input, it will be displayed in the content.
   *  - If a message + errors is input, they will be displayed ul+li style.
   * @param message Message to toast.
   * @param errors errors to toast (can be displayed without content).
   */
  public showWarning(message: string, errors: string[] = null, hideAfterMiliseconds: number = 5000) {
    this.show(
      this.translateService.instant('SHARED.ALERT_MODULE.WARNING'),
      message,
      NotificationType.warning,
      { delay: hideAfterMiliseconds },
      errors
    );
  }

  /**
   * Displays warnings according to the information provided as input:
   *  - If only one message is input, it will be displayed in the content.
   *  - If a message + errors is input, they will be displayed ul+li style.
   * @param message Message to toast (response, ApiActionResult or any).
   * @param errors errors to toast (can be displayed without content).
   */
  public showError(message: Response | ApiActionResult | any, errors: string[] = null, hideAfterMiliseconds: number = 5000) {
    if (message instanceof ApiActionResult) {
      const url = this.formatUrl(message);
      message =
        this.translateService.instant('COMMON.COMMAND.API_ERROR') + '(' + message.status + '-' + message.statusText + ' - ' + url + ' )';
    }
    this.show(
      this.translateService.instant('SHARED.ALERT_MODULE.ERROR'),
      message,
      NotificationType.error,
      { delay: hideAfterMiliseconds },
      errors
    );
  }

  public showApiError(httpErrorResponse: any) {
    if (!httpErrorResponse) {
      this.showError(this.translateService.instant('COMMON.COMMAND.AN_ERROR_OCCURRED'));
      return;
    }
    var errors = httpErrorResponse.error
    if (errors && errors.length > 0) {
      errors.forEach(e =>
        this.showError(e.key + ': ' + this.translateService.instant(e.value)));
    } else {
        this.showError(this.translateService.instant('COMMON.COMMAND.AN_ERROR_OCCURRED'));
    }
  }

  private show(header: string, body?: string, type?: string, options?: ToastOption, errors?: string[]) {
    const toast = new SimpleNotification();
    toast.header = header;
    toast.errors = errors;
    toast.message = body;
    toast.type = type;
    toast.options = options ? options : new ToastOption();
    this.toasts.push(toast);
  }

  public remove(toast: SimpleNotification) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  public clear() {
    this.toasts.splice(0, this.toasts.length);
  }

  public showValidationErrors(errors: ValidationError[]) {
    const _errors: string[] = [];
    for (let i = 0; i <= errors.length - 1; i++) {
      if (errors[i].Value.indexOf('|')) {
        const combinedFields = errors[i].Value.split('|');

        for (let c = 0; c <= combinedFields.length - 1; c++) {
          const translatedField = this.translateService.instant(
            'COMMON.FIELDVALIDATION.' + combinedFields[c].toUpperCase().replace('.', '_')
          );

          _errors.push(translatedField);
        }
      } else {
        _errors.push(errors[i].Value);
      }
    }

    this.show(this.translateService.instant('COMMON.VALIDATION.HAS_INVALID_FIELDS'), null, NotificationType.error, null, _errors);
  }

  // This method will return a shortenned url
  // - strip everything after -prd
  // - remove https://
  // for all non prd urls it will return the full url
  // on exception return the string no url
  private formatUrl(response: ApiActionResult) {
    try {
      if (response.commandError && response.commandError.url) {
        let url = response.commandError.url;
        const indexof = response.commandError.url.indexOf('-prd');
        if (indexof > 0) {
          url = response.commandError.url.substring(0, indexof);
          url = url.replace('https://', '');
        }
        return url;
      }
      return '-';
    } catch (err) {
      return 'No Url';
    }
  }
}
