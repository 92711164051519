import { Component, OnInit } from "@angular/core";
import { AuthService } from "@hrz/core/services/auth.service";

@Component({
  selector: 'hrz-user-initials',
  templateUrl: 'user-initials.component.html',
  styleUrls: ['./user-initials.component.scss'],
})
export class UserInitialsComponent implements OnInit {
  public initials: string;
  public menuVisible = false;
  public userName: string;
  constructor(
    private authService: AuthService
  ) { 
  }

  ngOnInit() {
    this.userName = this.authService.getUserName()
    this.initials = this.extractInitials(this.userName);
  }

  private extractInitials(email: string): string {
    
    const parts = email.split('@');

    if (parts.length !== 2) {
      return ''; 
    }
  
    const username = parts[0];
  
    if (!username) {
      return '';
    }
  
    const initials = username
      .split(/[_\.-]/)
      .map(part => {
        const letterMatch = part.match(/[A-Za-z]/);
        return letterMatch ? letterMatch[0].toUpperCase() : '';
      })
      .join('');
  
    return initials.length > 2 ? initials.slice(0, 2) : initials;
  }

  toggleMenu() {
    this.menuVisible = !this.menuVisible;
  }

}
