export class ChannelConfig {
  url: string;
  hubName: string;
  channel: string;

  constructor(_url: string, _hubName: string) {
    this.url = _url;
    this.hubName = _hubName;
  }
}
