import { Injectable, Optional, SkipSelf } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { LanguageCode } from '@hrz/core/models/multilangual/language-code';
import { ConfigurationManager } from '@hrz/core/models/configuration.manager';
import { ServiceActionHandler } from '@hrz/core/services/service-action-handler';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageCodeService {
  private _languageCodes: LanguageCode[];
  private _httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
  private _baseUrl = ConfigurationManager.AppSettings.multilangualManagementApi;
  private _languageCodeUrl = this._baseUrl + '/language-codes';
  constructor(
    private http: HttpClient,
    private serviceActionHandler: ServiceActionHandler,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: LanguageCodeService
  ) {
    if (parent) {
      appInsightsService.logException(new Error('LanguageCodeService is a Singleton and should only be loaded in AppModule.'));
    }
    this.list();
  }

  public list(): Observable<LanguageCode[]> {
    if (this._languageCodes != null && this._languageCodes.length > 0) {
      return of(this._languageCodes);
    } else {
      return this.http
        .get(this._languageCodeUrl).pipe(
        map(response => {
          const data = response as LanguageCode[];
          this._languageCodes = data;
          return this._languageCodes;
        }),
        catchError(this.serviceActionHandler.handleDefaultError),);
    }
  }

  public get(id: string): Observable<LanguageCode> {
    if (this._languageCodes != null && this._languageCodes.length > 0) {
      return of(this._languageCodes.filter(lg => lg.id === id)[0]);
    } else {
      return this.http.get(`${this._languageCodeUrl}/${id}`).pipe(
        map(response => response as LanguageCode),
        catchError(this.serviceActionHandler.handleDefaultError)
      );
    }
  }

  public update(id: string, item: LanguageCode): Observable<Response> {
    return this.http
      .put(`${this._languageCodeUrl}/${id}`, JSON.stringify(item), { headers: this._httpHeaders })
      .pipe(catchError(this.serviceActionHandler.handleDefaultError));
  }

  public create(item: LanguageCode): Observable<LanguageCode> {
    return this.http.post(`${this._languageCodeUrl}`, JSON.stringify(item), { headers: this._httpHeaders }).pipe(
      map(response => response as LanguageCode),
      catchError(this.serviceActionHandler.handleDefaultError)
    );
  }

  public listCultureCodes(): Observable<LanguageCode[]> {
    return this.http.get(`${this._languageCodeUrl}/culture-info`).pipe(
      map(response => response as LanguageCode[]),
      catchError(this.serviceActionHandler.handleDefaultError)
    );
  }
}
