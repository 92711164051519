import { NgModule } from '@angular/core';
import { DataFilterPipe } from './data-filter.pipe';
import { GenericDataFilterPipe } from './generic-data-filter.pipe';
import { AddressDisplayPipe } from './address-display.pipe';
import { MapToIterable, MapToSortedIterable } from './map-to-iterable.pipe';
import { EmailDisplayPipe } from './email-display.pipe';
import { MoneyDisplayPipe } from './money-display.pipe';
import { PercentageDisplayPipe } from './percentage-display.pipe';
import { DossierFilterPipe } from './dossier-filter.pipe';
import { webauthorityFilterPipe } from './webauthority-filter.pipe';
import { AuthorizePipe } from './authorize.pipe';
import { MomentDatePipe, MomentDateLocalPipe } from './moment-date-pipe';
import { FilenameWithSuffixPipe } from './filename-with-suffix.pipe';
import { SelectFieldRegexPipe, SelectFieldFormatPipe } from './content-validation.pipe';

@NgModule({
  declarations: [
    DataFilterPipe,
    GenericDataFilterPipe,
    AddressDisplayPipe,
    MapToIterable,
    MapToSortedIterable,
    EmailDisplayPipe,
    MoneyDisplayPipe,
    PercentageDisplayPipe,
    DossierFilterPipe,
    webauthorityFilterPipe,
    MomentDatePipe,
    MomentDateLocalPipe,
    AuthorizePipe,
    FilenameWithSuffixPipe,
    SelectFieldRegexPipe,
    SelectFieldFormatPipe,
  ],
  exports: [
    DataFilterPipe,
    GenericDataFilterPipe,
    AddressDisplayPipe,
    MapToIterable,
    MapToSortedIterable,
    EmailDisplayPipe,
    MoneyDisplayPipe,
    PercentageDisplayPipe,
    DossierFilterPipe,
    webauthorityFilterPipe,
    MomentDatePipe,
    MomentDateLocalPipe,
    AuthorizePipe,
    FilenameWithSuffixPipe,
    SelectFieldRegexPipe,
    SelectFieldFormatPipe,
  ],
})
export class PipesModule {}
