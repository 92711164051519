import { catchError, map } from 'rxjs/operators';
import { Injectable, Optional, SkipSelf } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Alert } from '@hrz/core/models/alert';
import { ConfigurationManager } from '@hrz/core/models/configuration.manager';
import { ServiceActionHandler } from '@hrz/core/services/service-action-handler';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class UtilProxyService {
  private readonly _alertSource = new BehaviorSubject<Alert[]>([]);
  private baseUrl = ConfigurationManager.AppSettings.utilProxyApi;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient,
    private serviceActionHandler: ServiceActionHandler,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: UtilProxyService
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('UtilProxyService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  protected getApiPath(): string {
    return '';
  }
  protected getBaseUrl(): string {
    return ConfigurationManager.AppSettings.utilProxyApi;
  }

  getAlerts(): Observable<Alert[]> {
    if (this._alertSource.getValue().length > 0) {
      return this._alertSource.asObservable();
    } else {
      return this.http.get<Alert[]>(`${this.baseUrl}/v1/alert`, { headers: this.headers }).pipe(
        map(response => {
          this._alertSource.next(response);
          return this._alertSource.getValue();
        }),
        catchError(this.serviceActionHandler.handleDefaultError)
      );
    }
  }
}
