import { ValidationError } from '@hrz/core/models/validation-error';

export class ApiActionResult<T = any> {
  status: number;
  statusText: string;
  entity: T;
  commandValidations: ValidationError[];
  commandHasValidationErrors: boolean;
  commandError: any;
  commandSucceeded: boolean;
  commandWarnings: ValidationError[];
  commandHasValidationWarnings: boolean;
}
