import { EMPTY, Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ApiActionResult } from '@hrz/core/models/api-action-result';
import { ValidationError } from '@hrz/core/models/validation-error';
import { Injectable, Optional, SkipSelf } from '@angular/core';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceActionHandler {

  constructor(private translateService: TranslateService,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: ServiceActionHandler
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('ServiceActionHandler is a Singleton and should only be loaded in AppModule.'));
    }
  }

  public handleActionSuccess(response: any): Promise<ApiActionResult> {
    const apiResult = this.mapActionSuccess(response);
    return Promise.resolve(apiResult);
  }

  public mapActionSuccess(response: any): ApiActionResult {
    let apiResult = new ApiActionResult();
    apiResult.status = 200;
    apiResult.statusText = 'Ok';
    apiResult.commandSucceeded = true;
    if (response) {
      apiResult = this.mapActionBody(response, apiResult);
    }
    apiResult.commandHasValidationErrors = false;
    return apiResult;
  }

  private mapActionBody(response: any, apiResult: ApiActionResult): ApiActionResult {
    if (response.Warnings && response.Warnings.length > 0) {
      apiResult.commandHasValidationWarnings = true;
      apiResult.commandWarnings = this.getTranslatedMessages(response.Warnings);
      apiResult.entity = response.Entity;
    } else {
      apiResult.entity = response;
    }
    return apiResult;
  }

  public handleActionError(error: any): Promise<ApiActionResult> {
    console.error('handleActionError: ', error); // for demo purposes only
    const apiResult = this.mapActionError(error);
    return Promise.resolve(apiResult);
  }

  public handleObservableActionError(error: any): Observable<any> {
    return of(this.mapActionError(error));
  }

  private mapActionError(errorResponse: any): ApiActionResult {
    const apiResult = new ApiActionResult();
    apiResult.status = errorResponse.status;
    apiResult.statusText = errorResponse.statusText;
    apiResult.commandError = errorResponse.message || errorResponse;
    apiResult.commandSucceeded = false;
    // This means validation errors detected....
    if (errorResponse.status === 406 || errorResponse.status === 400) {
      const errors = this.extractErrors(errorResponse);
      apiResult.commandValidations = this.getTranslatedMessages(errors);
      apiResult.commandHasValidationErrors = true;
      apiResult.commandError = 'Your api action command was not valid, see validation errors.';
    }
    return apiResult;
  }

  private extractErrors(response: any) {

    if (response.error && response.error.length > 0) {
      return  response.error;
    } else if (response.error && response.error.Errors && response.error.Errors.length > 0) {
      return  response.error.Errors;
    } else {
      return response;
    }
  }

  private getTranslatedMessages(errors: any) {
    let validationErrors: ValidationError[] = [];
    if (errors.length) {
      let allErrors: ValidationError[] = [];
      errors.forEach(error => {
        const value = error.Value || error.value;
        const splitted = value.split('|');
        if (splitted.length > 1) {
          allErrors = allErrors.concat(splitted.map(item => new ValidationError(error.Key || error.key, item)));
        } else {
          allErrors.push(error);
        }
        // allErrors = allErrors.concat(error.value.split("|"))
      });
      validationErrors = allErrors.map((item: any) => this.getValidationErrorIgnoringCase(item));
    } else {
      validationErrors.push(new ValidationError('', this.translateErrorMessage(errors.message)));
    }
    return validationErrors;
  }

  public handleDefaultError(error: any): Promise<any> {
    if (error.status === 404) {
      return Promise.resolve(null);
    }
    if (error.error) {
      return Promise.reject(error);
    }
    return Promise.reject(error.message || error);
  }

  public handleDefaultObservableError(error: any): Observable<any> {
    if (error.status === 404) {
      return EMPTY ;
    }

    throw new Error(error.message || error);
  }

  private getValidationErrorIgnoringCase(item: any): ValidationError {
    const value = item.Value || item.value;
    return new ValidationError(item.Key || item.key, this.translateErrorMessage(value));
  }

  private translateErrorMessage(message: string): string {
    // A returned error message can contain a message, a translation key or a translation key with additional text
    // that should not be translated
    // In the last case the format is TRANSLATION_KEY<<additional message>>
    const regex = /(.*)<<(.*)>>/g;

    const matches = regex.exec(message);

    if (matches != null && matches.length === 3) {
      return `${this.translateService.instant(matches[1])} ${matches[2]}`;
    }

    let translation = this.translateService.instant(message);
    if (translation === message) {
      const key = 'COMMON.FIELDVALIDATION.' + message.toUpperCase().replace(/\./g, '_');
      const translationAttempt = this.translateService.instant(key);
      if (translationAttempt !== key) {
        translation = translationAttempt;
      }
    }
    return translation;
  }
}
