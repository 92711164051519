import { Injectable, Optional, SkipSelf } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { Config } from '@hrz/core/models/config';
import { ConfigurationManager } from '@hrz/core/models/configuration.manager';
import { ServiceActionHandler } from '@hrz/core/services/service-action-handler';
import { AppInsightsService } from './app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private dataMemoryCache: Config;
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private readonly http: HttpClient,
    private readonly serviceActionHandler: ServiceActionHandler,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: ConfigService
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('ConfigService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  private getApiServiceUrl() {
    return ConfigurationManager.AppSettings.dossierApi + '/config';
  }

  public async get(): Promise<Config> {
    if (!this.dataMemoryCache) {
      this.dataMemoryCache = await this.http
        .get(this.getApiServiceUrl(), { headers: this.httpHeaders })
        .pipe(
          map(response => {
            return response as Config;
          })
        )
        .toPromise()
        .catch(this.serviceActionHandler.handleDefaultError);
    }
    return this.dataMemoryCache;
  }
}
