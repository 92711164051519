import { trim, findLastIndex } from 'lodash-es';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ValidationError } from '@hrz/core/models/validation-error';

class NumberHelper {
  public static isInRange(value: number, min: number, max: number): boolean {
    return min <= value && value <= max;
  }
}

export class FormHelpers {
  public static markAsPristine(formGroup: FormGroup): void {
    if (!formGroup) { return; }
    Object.keys(formGroup.controls).forEach(control => formGroup.controls[control].markAsPristine());
  }
}

export class ObjectHelpers {
  public static copyAsDraft(originalObject: any): any {
    return JSON.parse(JSON.stringify(originalObject));
  }

  public static clone<T>(object: T): T {
    const json = JSON.stringify(object);
    return JSON.parse(json);
  }

  public static convertDictionaryToKeyValue(dict: Object): any[] {
    const a = [];
    for (const key in dict) {
      if (dict.hasOwnProperty(key)) {
        a.push({ Key: key, Value: dict[key] });
      }
    }
    return a;
  }
}

export class ValidationHelpers {
  public static extractNotMapped(validationErrors: ValidationError[]): string[] {
    const notMapped: string[] = [];
    validationErrors.map(command => {
      if (trim(command.Key) === '') { notMapped.push(command.Value); }
    });
    return notMapped;
  }

  public static extractMapped(validationErrors: ValidationError[]): string[] {
    const mapped: string[] = [];
    validationErrors.map(command => {
      if (trim(command.Key) !== '') { mapped.push(command.Value); }
    });
    return mapped;
  }

  public static isGreaterThanZero(control: AbstractControl): void {
    const value = control.value;
    if (!value || value <= 0) {
      control.setErrors({ ValueOutOfRange: true });
    } else {
      control.setErrors(null);
    }
  }

  public static isInRange(control: AbstractControl, min: number, max: number): void {
    if (!NumberHelper.isInRange(control.value, min, max)) {
      let errors = control.errors;
      if (!errors) {
        errors = {};
      }
      errors['ValueOutOfRange'] = true;
      control.setErrors(errors);
    }
  }
}

export class NavigationHelper {
  // Navigates to http://[current-url]/[activated-route]
  public static navigateToStart(router: Router, activatedRoute: ActivatedRoute) {
    // activatedRoute.snapshot.url.map(segment => {
    //    navigateUrl = navigateUrl.replace('/' + segment.path, '');
    // });
    const navigateUrl = NavigationHelper.extractUrlBeforeActivatedRoute(router, activatedRoute, true);
    router.navigate([navigateUrl]);
  }
  // Navigates to: http://[current-url]/[activated-route]/newRoute
  public static navigateTo(router: Router, activatedRoute: ActivatedRoute, newRoute: string) {
    const navigateUrl = NavigationHelper.extractUrlBeforeActivatedRoute(router, activatedRoute);
    router.navigate([navigateUrl + newRoute]);
  }
  // Navigates to http://[current url](without-checking the activated-route)/addRoute
  public static navigate(router: Router, addRoute: string) {
    router.navigate([router.url + addRoute]);
  }

  public static extractUrlBeforeActivatedRoute(
    router: Router,
    activatedRoute: ActivatedRoute,
    removeBreadCrumClearUrlValue?: boolean
  ): string {
    let navigateUrl = router.url;
    const splitted = navigateUrl.split('/');
    activatedRoute.snapshot.url.map(segment => {
      const index = findLastIndex(splitted, o => o === segment.path);
      if (index > -1) { splitted.splice(index, 1); }
    });

    const breadCrumClearUrlValue = activatedRoute.snapshot.data.breadCrumClearUrlValue;
    if (breadCrumClearUrlValue && removeBreadCrumClearUrlValue) {
      const foundClearIndex = findLastIndex(splitted, o => o === breadCrumClearUrlValue.replace('/', ''));
      if (foundClearIndex > -1) { splitted.splice(foundClearIndex, 1); }
    }

    navigateUrl = splitted.join('/');
    return navigateUrl;
  }
}

export class ByteHelpers {
  // I think we should know the content type so we can set the contenttype correctly...
  public static arrayBufferToBase64(arrayBuffer: any): string {
    let base64 = '';
    const encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    const bytes = new Uint8Array(arrayBuffer);
    const byteLength = bytes.byteLength;
    const byteRemainder = byteLength % 3;
    const mainLength = byteLength - byteRemainder;
    let a, b, c, d;
    let chunk;

    // Main loop deals with bytes in chunks of 3
    /* tslint:disable:no-bitwise */
    for (let i = 0; i < mainLength; i = i + 3) {
      // Combine the three bytes into a single integer
      chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];
      // Use bitmasks to extract 6-bit segments from the triplet
      a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
      b = (chunk & 258048) >> 12; // 258048 = (2^6 - 1) << 12
      c = (chunk & 4032) >> 6; // 4032 = (2^6 - 1) << 6
      d = chunk & 63; // 63 = 2^6 - 1
      // Convert the raw binary segments to the appropriate ASCII encoding
      base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
    }
    // Deal with the remaining bytes and padding
    if (byteRemainder === 1) {
      chunk = bytes[mainLength];
      a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2;
      // Set the 4 least significant bits to zero
      b = (chunk & 3) << 4; // 3 = 2^2 - 1;
      base64 += encodings[a] + encodings[b] + '==';
    } else if (byteRemainder === 2) {
      chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];
      a = (chunk & 16128) >> 8; // 16128 = (2^6 - 1) << 8;
      b = (chunk & 1008) >> 4; // 1008 = (2^6 - 1) << 4;
      // Set the 2 least significant bits to zero
      c = (chunk & 15) << 2; // 15 = 2^4 - 1;
      base64 += encodings[a] + encodings[b] + encodings[c] + '=';
    }
    /* tslint:enable:no-bitwise */
    return base64;
  }
}

export class FileNameHelpers {
  public static includeSuffix(originalFileNameWithExtension: string, includeSuffix: string): string {
    if (!originalFileNameWithExtension) { return ''; }
    if (!includeSuffix) { return originalFileNameWithExtension; }

    const withoutExtension = originalFileNameWithExtension.substring(0, originalFileNameWithExtension.indexOf('.'));
    const ext = originalFileNameWithExtension.substring(originalFileNameWithExtension.indexOf('.'), originalFileNameWithExtension.length);

    return withoutExtension + ' (' + includeSuffix + ')' + ext;
  }
}

export class TimingHelper {
  public static async delay(milliseconds: number): Promise<void> {
    const promise = new Promise<void>(resolve => {
      setTimeout(resolve, milliseconds);
    });
    return promise;
  }
}
