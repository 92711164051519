import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailDisplay',
})
export class EmailDisplayPipe implements PipeTransform {
  transform(
    email: string,
    hideDomain: boolean
  ): any {
    if (hideDomain) {
      const atPos = email.indexOf('@');
      if (atPos !== -1) {
        return email.substring(0, atPos);
      }
    }
    return email;
  }
}