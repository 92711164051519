
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Permission } from '@hrz/core/models/enums/permission.enum';
import { NotificationEventService } from '@hrz/core/services/notification-event.service';

@Component({
  selector: 'hrz-tool-bar',
  templateUrl: './toolbar.component.html',
  styleUrls: ["./toolbar.component.scss"]
})
export class ToolBarComponent implements OnInit {
  @Output() expendedChange: EventEmitter<void> = new EventEmitter<void>();

  public newEventsCount = 0;
  public eventsReadSubscription: any;
  perm = Permission;

  constructor(
    private notificationService: NotificationEventService,
  ) {
    console.log('FullLayoutComponent.constructor()');
  }

  ngOnInit(): void {
    console.log('FullLayoutComponent.ngOnInit()');


    this.notificationService.getNewEvents().then(count => {
      this.newEventsCount = count;
    });

    this.eventsReadSubscription = this.notificationService.onEventsRead.subscribe((_amountOfEvents: number) => {
      this.notificationService.getNewEvents().then(count => {
        this.newEventsCount = count;
      });
    });
  }

  OnExpandChange() {
    this.expendedChange.emit();
  }
}