import { Injectable, EventEmitter, SkipSelf, Optional } from '@angular/core';

import { ConfigurationManager } from '@hrz/core/models/configuration.manager';
import { ServiceActionHandler } from '@hrz/core/services/service-action-handler';
import { HttpClient } from '@angular/common/http';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationEventService {
  private baseUrl = ConfigurationManager.AppSettings.notificationsApi;
  private customFieldUrl = this.baseUrl;
  public onEventsRead: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private http: HttpClient,
    private serviceActionHandler: ServiceActionHandler,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: NotificationEventService
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('NotificationEventService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  public getNewEvents(): Promise<number> {
    console.log('NotificationEventService.getNewEvents()');
    return this.http
      .get<number>(this.customFieldUrl + '/notification-events/new-event-count')
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public triggerEventsRead(amountOfEvents: number): void {
    console.log('NotificationEventService.triggerEventsRead()');
    if (this.onEventsRead) {
      console.log('NotificationEventService.onEventsRead() with amount:', amountOfEvents);
      this.onEventsRead.emit(amountOfEvents);
    }
  }
}
