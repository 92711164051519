<div class="admin-layout" 
 [ngStyle]="{'grid-template-columns': 
            (this.expanded)
              ? (this.isResponsive ? '0px auto' :'60px auto') 
              : (this.isResponsive ? '90% auto' :'300px auto')}" >

    <hrz-side-bar *ngIf="isResponsive"
                   [(isExpended)] ="expanded" 
                   (expendedChange)="toggleSidebar()"
                   [(isResponsiveMode)] ="isResponsive"> 
                  </hrz-side-bar>

    <hrz-side-bar *ngIf="!isResponsive"
                  [@expandSidebar]="expanded ? 'expanded' : 'collapsed'"
                  [(isExpended)] ="expanded" 
                  (expendedChange)="toggleSidebar()"
                  [(isResponsiveMode)] ="isResponsive" > 
                 </hrz-side-bar>

  <div class="content">
    <div class="hrz-navbar">
      <hrz-tool-bar
      (expendedChange)="toggleSidebar()"></hrz-tool-bar>
    </div>
  
    <div class="content-outlet">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<hrz-scroll-to-top></hrz-scroll-to-top>

<div class="toaster">
    <hrz-toaster></hrz-toaster>
</div>
