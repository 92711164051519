import { NgModule } from '@angular/core';
import {
  Routes,
  RouterModule,
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { SlimLoadingBarService } from './core/services/slim-loading-bar.service';
import { PermissionGuard } from '@hrz/core/guards/permission-guard.service';
import { Permission as Perm } from '@hrz/core/models/enums/permission.enum';

// Layouts
import { FullLayoutComponent } from '@hrz/core/components/layouts/full-layout.component';

const routes: Routes = [
  { path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full' },
  {
    path: '',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.HOME',
      requiredPermission: Perm.mi_db,
      fallback: '/dossiers',
    }, // We must define the layouts we want to display...
    children: [
      {
        path: 'dashboard',
        data: { title: 'BREADCRUMBS.DASHBOARD' },
        loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
    ],
  },
  {
    path: 'dossiers',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.DOSSIERS',
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/dossiers/dossier.module').then(m => m.DossierModule),
        canActivateChild: [PermissionGuard]
      },
    ],
  },
  {
    path: 'notifications',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.NOTIFICATIONS',
      requiredPermission: Perm.r_ds,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/notifications/notifications.module').then(m => m.NotificationsModule),
      },
    ],
  },
  {
    path: 'webauthority',
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.WEB_AUTHORITY',
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/webauthority/webauthority.module').then(m => m.WebauthorityModule),
      },
    ],
  },
  {
    path: 'countries',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.COUNTRIES',
      requiredPermission: Perm.r_cy,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/countries/country.module').then(m => m.CountryModule),
      },
    ],
  },
  {
    path: 'organizations',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.ORGANIZATIONS',
      requiredPermission: Perm.r_or,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/organizations/organization.module').then(m => m.OrganizationModule),
      },
    ],
  },
  {
    path: 'central-departments',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.CENTRAL_DEPARTMENTS',
      requiredPermission: Perm.r_cd,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/centraldepartments/central-department.module').then(m => m.CentralDepartmentModule),
      },
    ],
  },
  {
    path: 'fitting-station-groups',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.FITTING_STATIONS_GROUP',
      requiredPermission: Perm.r_fsg,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/fittingstationgroups/fitting-station-group.module').then(m => m.FittingStationGroupModule),
      },
    ],
  },
  {
    path: 'discount-approval-config',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.DISCOUNT_APPROVAL_CONFIG.DISCOUNT_APPROVAL_CONFIG',
      requiredPermission: Perm.r_dac,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/discountapprovalconfig/discount-approval-config.module').then(m => m.DiscountApprovalConfigModule),
      },
    ],
  },
  {
    path: 'accounts',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.ACCOUNTS',
      requiredPermission: Perm.r_ac,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/accounts/account.module').then(m => m.AccountModule),
      },
    ],
  },
  {
    path: 'origin',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.ORIGINS',
      requiredPermission: Perm.cs_cc_o_r,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/origin/origin.module').then(m => m.OriginModule),
      },
    ],
  },
  // GDES72
  {
    path: 'procedures',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.PROCEDURES',
      requiredPermission: Perm.ac_r,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/procedures/procedure.module').then(m => m.ProcedureModule),
      },
    ],
  },
  {
    path: 'countersales',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.COUNTERSALES',
      requiredPermission: Perm.r_cs,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/countersales/countersale.module').then(m => m.CountersaleModule),
      },
    ],
  },
  {
    path: 'stock',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.STOCK',
      requiredPermission: Perm.mi_sic,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/stock/stock.module').then(m => m.StockModule),
      },
    ],
  },
  {
    path: 'tax',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.TAX',
      requiredPermission: Perm.r_tax,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/tax/tax.module').then(m => m.TaxModule),
      },
    ],
  },
  {
    path: 'availability-calendar',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.AVAILABILITY',
      requiredPermission: Perm.r_cav,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/availabilitycalendar/availability-calendar.module').then(m => m.AvailabilityCalendarModule),
      },
    ],
  },
  {
    path: 'directsales',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.DIRECT_SALES',
      requiredPermission: Perm.r_dsc,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/directsales/directsales.module').then(m => m.DirectSalesModule),
      },
    ],
  },
  {
    path: 'quotation',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.QUOTATIONS',
      requiredPermission: Perm.r_dsc,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/quotation/quotation.module').then(m => m.QuotationModule),
      },
    ],
  },
  {
    path: 'logistics',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.LOGISTICS.ROUTE',
      requiredPermissions: [Perm.r_reqiv, Perm.r_erpiv],
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/logistics/logistics.module').then(m => m.LogisticsModule),
      },
    ],
  },
  {
    path: 'sendtoaccount',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.SEND_TO_ACCOUNT',
      requiredPermission: Perm.r_snd,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/sendtoaccount/sendtoaccount.module').then(m => m.SendToAccountModule),
      },
    ],
  },
  {
    path: 'batchfinancialvalidation',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.BATCH_FINANCIAL_VALIDATION',
      requiredPermission: Perm.u_bfv,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/batchfinancialvalidation/batchfinancialvalidation.module').then(m => m.BatchFinancialValidationModule),
      },
    ],
  },
  {
    path: 'senddocuments',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.SEND_DOCUMENTS',
      requiredPermission: Perm.r_snd,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/senddocuments/senddocuments.module').then(m => m.SendDocumentsModule),
      },
    ],
  },
  {
    path: 'message-center',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.MESSAGE_CENTER',
      requiredPermission: Perm.r_msg,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/messagecenter/message-center.module').then(m => m.MessageCenterModule),
      },
    ],
  },
  {
    path: 'templates',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.TEMPLATE_MANAGEMENT',
      requiredPermission: Perm.r_tp,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/template/template.module').then(m => m.TemplateModule),
      },
    ],
  },
  {
    path: 'multilanguage',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: { title: 'BREADCRUMBS.TRANSLATION_MANAGEMENT', requiredPermission: Perm.r_lc },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/multilanguage/multilanguage.module').then(m => m.MultilanguageModule),
      },
    ],
  },
  {
    path: 'codification',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.CODIFICATIONS',
      requiredPermission: Perm.r_lc, // TODO permissions
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/codification/codification.module').then(m => m.CodificationModule),
      },
    ],
  },
  {
    path: 'protocols',
    component: FullLayoutComponent,
    data: { title: 'BREADCRUMBS.PROTOCOLS' },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/protocols/protocols.module').then(m => m.ProtocolsModule),
      },
    ],
  },
  {
    path: 'users',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.USER_MANAGEMENT',
      requiredPermission: Perm.um_um,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/usermanagement/usermanagement.module').then(m => m.UserManagementModule),
      },
    ],
  },
  {
    path: 'cases',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.CASES',
      requiredPermission: Perm.r_ds,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/cases/cases.module').then(m => m.CasesModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  declarations: [],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(
    router: Router,
    private slimLoadingBarService: SlimLoadingBarService
  ) {
    router.events.subscribe((event: RouterEvent): void => {
      this.navigationInterceptor(event);
    });
  }

  navigationInterceptor(event: RouterEvent): void {
    let PageName, navUrl, navUrlArray;
    if (event instanceof NavigationStart) {
      console.info('Started the navigation!' + event.url);
      navUrl = event.url;
      navUrlArray = navUrl.split('/');
      PageName = 'page-' + navUrlArray[navUrlArray.length - 1];

      this.slimLoadingBarService.start();
      if (document.querySelector('body').classList.contains('btn-compact-pressed')) {
        document.querySelector('body').classList.add('sidebar-compact');
      } else {
        document.querySelector('body').classList.remove('sidebar-compact');
      }
      document.querySelector('body').classList.add(PageName);
    }
    if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
      console.info('Ended the navigation!');
      // this.loading = false;
      navUrl = event.url;
      navUrlArray = navUrl.split('/');
      PageName = 'page-' + navUrlArray[navUrlArray.length - 1];
      this.slimLoadingBarService.complete();
      document.querySelector('body').classList.remove(PageName);
    }
  }
}
