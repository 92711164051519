<div class="navbar-content">
    <div class="toggle-button">

        <button class="hrz-navbar-toggler" type="button" 
            (click)="OnExpandChange()">&#9776;</button>
    </div>

    <h1 class="header-page-title">
        <ul>
            <hrz-breadcrumbs></hrz-breadcrumbs>
        </ul>
    </h1>

    <div class="switch-button">
        <hrz-switch-version></hrz-switch-version>
    </div>

    <div class="notification" style=" list-style: none;" routerLinkActive="active" [routerLink]="['/notifications']"
        title="{{'MAIN_MENU.NOTIFICATIONS' | translate}}">
        <i class="fa fa-bell" aria-hidden="true">
            <span class="badge" title="{{'MAIN_MENU.NEW_EVENTS' | translate}}">{{newEventsCount}}</span>
        </i>
    </div>

    <hrz-user-profile-dropdown></hrz-user-profile-dropdown>
</div>