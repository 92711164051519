import { Injectable, Optional, SkipSelf } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Country } from '@hrz/core/models/country';
import { ApiActionResult } from '@hrz/core/models/api-action-result';
import { ConfigurationManager } from '@hrz/core/models/configuration.manager';
import { ServiceActionHandler } from '@hrz/core/services/service-action-handler';
import { AppInsightsService } from './app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private dataMemoryCache: Country[]; // In-Memory cache
  private baseUrl = ConfigurationManager.AppSettings.organizationApi;
  private geonamesBaseUrl = ConfigurationManager.AppSettings.dossierApi;
  private countryUrl = this.baseUrl + '/country';
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient,
    private serviceActionHandler: ServiceActionHandler,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: CountryService
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('CountryService is a Singleton and should only be loaded in AppModule.'));
    }
    // We load immediatly the data in cache
    this.loadAll();
  }

  public loadAll(): Promise<Country[]> {
    if (this.dataMemoryCache != null && this.dataMemoryCache.length > 0) {
      // Leverage local cache
      return new Promise<Country[]>((resolve, _) => {
        resolve(this.dataMemoryCache);
      });
    } else {
      // Retrieve from API
      return this.http
        .get(this.countryUrl)
        .toPromise()
        .then(response => {
          this.dataMemoryCache = response as Country[];
          return this.dataMemoryCache;
        })
        .catch(this.serviceActionHandler.handleDefaultError);
    }
  }

  public getCountry(id: number): Promise<Country> {
    if (this.dataMemoryCache != null && this.dataMemoryCache.length > 0) {
      // Leverage local cache
      return new Promise<Country>((resolve, _) => {
        resolve(this.dataMemoryCache.find(item => item.Id === id));
      });
    } else {
      // Retrieve from API
      return this.http
        .get(this.countryUrl + '/' + id)
        .toPromise()
        .then(response => {
          return response as Country;
        })
        .catch(this.serviceActionHandler.handleDefaultError);
    }
  }

  public createCountry(country: Country): Promise<ApiActionResult> {
    this.purgeLocalDataMemoryCache();
    const url = `${this.countryUrl}`;
    const createCommand = {
      Name: country.Name,
      Currency: country.Currency,
      Culture: country.Culture,
      IsoCode: country.IsoCode,
      CallingCode: country.CallingCode,
    };
    return this.http
      .post(url, JSON.stringify(createCommand), { headers: this.httpHeaders })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public updateCountry(country: Country): Promise<ApiActionResult> {
    this.purgeLocalDataMemoryCache();
    const url = `${this.countryUrl}/${country.Id}`;
    const updateCommand = {
      CountryId: country.Id,
      Name: country.Name,
      Currency: country.Currency,
      Culture: country.Culture,
      IsoCode: country.IsoCode,
      CallingCode: country.CallingCode,
    };
    return this.http
      .put(url, JSON.stringify(updateCommand), { headers: this.httpHeaders })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public deleteCountry(countryId: number): Promise<ApiActionResult> {
    this.purgeLocalDataMemoryCache();
    const url = `${this.countryUrl}/${countryId}`;
    return this.http
      .delete(url, { headers: this.httpHeaders })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public postalCodeLookup(postalCode: string, twoLetterCountryIsoCode: string): Promise<ApiActionResult> {
    const url =
      `${this.geonamesBaseUrl}/geonames/postalcodelookup?postalCode=${postalCode}` + `&twoLetterCountryIsoCode=${twoLetterCountryIsoCode}`;
    return this.http
      .get(url, { headers: this.httpHeaders })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  // To purge local cache in case of data modification
  private purgeLocalDataMemoryCache() {
    delete this.dataMemoryCache;
  }
}
