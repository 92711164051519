import { Component } from '@angular/core';
import { ToasterService } from '@hrz/core/services/toaster.service';
import { staggerCardList, toastList } from './animations';

@Component({
  selector: 'hrz-toaster',
  template: `
    <div class="toast-container p-2" [@toastList]="toaster.toasts.length">
      <hrz-toast *ngFor="let toast of toaster.toasts" [toast]="toast" (hidden)="toaster.remove(toast)"></hrz-toast>
    </div>
  `,
  styleUrls: ['./toaster.component.scss'],
  animations: [staggerCardList, toastList],
})
export class ToasterComponent {
  constructor(public toaster: ToasterService) {}
}
