import { Injectable, Inject, Optional, SkipSelf } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import { SignalrWindow } from '@hrz/core/models/signalr/signalr-window';
import { ConnectionState } from '@hrz/core/models/signalr/connection-state';
import { ChannelConfig } from '@hrz/core/models/signalr/channel-config';
import { ChannelSubject } from '@hrz/core/models/signalr/channel-subject';
import { ChannelEvent } from '@hrz/core/models/signalr/channel-event';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';
import { environment } from '@hrz/env';

@Injectable({
  providedIn : 'root'
})
export class SignalrDossierService {
  starting$: Observable<any>;
  connectionState$: Observable<ConnectionState>;
  error$: Observable<string>;

  private connectionStateSubject = new Subject<ConnectionState>();
  private startingSubject = new Subject<any>();
  private errorSubject = new Subject<any>();

  private hubConnection: any;
  private hubProxy: any;

  private subjects = new Array<ChannelSubject>();

  private channelConfig: ChannelConfig = new ChannelConfig(environment.signalrEndpoint, 'EventHub');

  constructor(@Inject(SignalrWindow) private window: SignalrWindow,
  appInsightsService: AppInsightsService,
  @Optional() @SkipSelf() parent?: SignalrDossierService) {
    if (parent) {
      appInsightsService.logException(new Error('LanguageCodeService is a Singleton and should only be loaded in AppModule.'));
    }
    if (this.window.$ === undefined) {
      throw new Error('The variable $ is not defined. Please check that Jquery is loaded properly');
    }
    if (this.window.$.hubConnection === undefined) {
      throw new Error('The $.hubConnection() function is not defined. Please check that SignalR is loaded properly');
    }

    this.connectionState$ = this.connectionStateSubject.asObservable();
    this.error$ = this.errorSubject.asObservable();
    this.starting$ = this.startingSubject.asObservable();

    this.hubConnection = this.window.$.hubConnection();
    this.hubConnection.url = this.channelConfig.url;
    this.hubProxy = this.hubConnection.createHubProxy(this.channelConfig.hubName);

    this.hubConnection.stateChanged((state: any) => {
      let newState = ConnectionState.Connecting;

      switch (state.newState) {
        case this.window.$.signalR.connectionState.connecting:
          newState = ConnectionState.Connecting;
          break;
        case this.window.$.signalR.connectionState.connected:
          newState = ConnectionState.Connected;
          break;
        case this.window.$.signalR.connectionState.reconnecting:
          newState = ConnectionState.Reconnecting;
          break;
        case this.window.$.signalR.connectionState.disconnected:
          newState = ConnectionState.Disconnected;
          break;
      }

      this.connectionStateSubject.next(newState);
    });

    this.hubConnection.error((error: any) => {
      this.errorSubject.next(error);
    });

    this.hubProxy.on('onEvent', (channel: string, ev: ChannelEvent) => {
      console.log(`SignalrDossierService.onEvent() SignalR: Event received on ${channel} channel. Event:`, ev);
      const channelSub = this.subjects.find((x: ChannelSubject) => {
        return x.channel === channel;
      }) as ChannelSubject;

      if (channelSub !== undefined) {
        return channelSub.subject.next(ev);
      }
    });
  }

  start(): void {
    this.hubConnection
      .start({ withCredentials: false })
      .done(() => {
        this.startingSubject.next();
      })
      .fail((error: any) => {
        this.startingSubject.error(error);
      });
  }

  sub(channel: string): Observable<ChannelEvent> {
    let channelSub = this.subjects.find((x: ChannelSubject) => {
      return x.channel === channel;
    }) as ChannelSubject;

    if (channelSub !== undefined) {
      console.log(`SignalrDossierService.sub() SignalR: Found existing observable for ${channel} channel`);
      return channelSub.subject.asObservable();
    }
    console.log(`SignalrDossierService.sub() SignalR: Created new observable for ${channel} channel`);

    channelSub = new ChannelSubject();
    channelSub.channel = channel;
    channelSub.subject = new Subject<ChannelEvent>();
    this.subjects.push(channelSub);

    this.starting$.subscribe(
      () => {
        // Only subscribe if the connection state is 1 (connected)
        // If it is not connected yet, the starting$ subscription will trigger this.
        if (this.hubProxy.connection.state === 1) {
          this.hubProxy
            .invoke('Subscribe', channel)
            .done(() => {
              console.log(`SignalrDossierService.sub() SignalR: Subscribed to ${channel} channel`);
            })
            .fail((error: any) => {
              channelSub.subject.error(error);
            });
        }
      },
      (error: any) => {
        channelSub.subject.error(error);
      }
    );
    this.startingSubject.next();

    return channelSub.subject.asObservable();
  }

  publish(ev: ChannelEvent): void {
    this.hubProxy.invoke('Publish', ev);
  }
}
