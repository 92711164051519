import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentageDisplay',
})
export class PercentageDisplayPipe implements PipeTransform {
  transform(property: number, _mode: string): any {
    let formatted = '0 %';

    if (property > 0) {
      formatted = property + ' %';
    }
    return formatted;
  }
}
