import { Pipe, PipeTransform } from '@angular/core';
import { Alert } from '@hrz/core/models/alert';
@Pipe({ name: 'message' })
export class AlertMessagePipe implements PipeTransform {
  transform(value: Alert, args: string): string {
    if (!value) { return ''; }
    if (value.I18n[args.toLowerCase()]) {
      return value.I18n[args.toLowerCase()];
    } else {
      return value.Message;
    }
  }
}
