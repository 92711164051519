import { Pipe } from '@angular/core';

@Pipe({
  name: 'mapToIterable',
})
export class MapToIterable {
  transform(dict: Object): any {
    const a = [];
    for (const key in dict) {
      if (dict.hasOwnProperty(key)) {
        a.push({ Key: key, Value: dict[key] });
      }
    }
    return a;
  }
}

@Pipe({
  name: 'mapToSortedIterable',
})
export class MapToSortedIterable {
  transform(dict: Object): any {
    const a = [];
    for (const key in dict) {
      if (dict.hasOwnProperty(key)) {
        a.push({ Key: key, Value: dict[key] });
      }
    }
    return a.sort(this.compareByValue);
  }

  compareByValue(a: any, b: any) {
    if (a.Value < b.Value) {
      return -1;
    }
    if (a.Value > b.Value) {
      return 1;
    }
    return 0;
  }
}
