const Permission = {
    ac_c: 'P:C' as 'P:C',	// Procedures : create
    ac_d: 'P:D' as 'P:D',	// Procedures : handle documents
    ac_r: 'P:R' as 'P:R',	// Procedures : read (including documents)
    c_ac: 'c:ac' as 'c:ac',	// Post Account
    c_ap_omc: 'c:ap:omc' as 'c:ap:omc',	// Post Appointment Override Max Capacity
    c_apm: 'c:apm' as 'c:apm',	// Post Create JobPromotion
    c_cr: 'c:cr' as 'c:cr',	// Post Codification
    c_cs: 'c:cs' as 'c:cs',	// Create Counter Sale
    c_dac: 'c:dac' as 'c:dac',	// Post Discount Approval Config
    c_fs: 'c:fs' as 'c:fs',	// Post FittingStation
    c_fs_ac: 'c:fs:ac' as 'c:fs:ac',	// Post FS Capacity
    c_fs_ace: 'c:fs:ace' as 'c:fs:ace',	// Post FS Capacity Exception
    c_fs_ba: 'c:fs:ba' as 'c:fs:ba',	// Post Fittingstation Blocked Account
    c_fs_fss: 'c:fs:fss' as 'c:fs:fss',	// Post FittingStation FittingStationService
    c_fs_jt: 'c:fs:jt' as 'c:fs:jt',	// Create FS Job Type Configuration
    c_fs_oh: 'c:fs:oh' as 'c:fs:oh',	// Post FittingStation Opening Hours
    c_fs_ph: 'c:fs:ph' as 'c:fs:ph',	// Post FittingStation Photo
    c_fs_sa: 'c:fs:sa' as 'c:fs:sa',	// Post FS Service Area
    c_fsg: 'c:fsg' as 'c:fsg',	// Post FittingStationGroup
    c_fsg_ct: 'c:fsg:ct' as 'c:fsg:ct',	// Post FittingStationGroup Contact
    c_jfr: 'c:jfr' as 'c:jfr',	// Post Job Fitting Recalculate
    c_ls_cs: 'c:ls:cs' as 'c:ls:cs',	// Create Labor Service Countersale
    c_nts: 'c:nts' as 'c:nts',	// Create Notes
    c_po: 'c:po' as 'c:po',	// Create Purchase Order
    cs_cc_f: 'cs:cc:f' as 'cs:cc:f',	// Case System : Call Centre : Fees
    cs_cc_lv: 'cs:cc:lv' as 'cs:cc:lv',	// Case System : Call Centre : Limited Views
    cs_cc_o_c: 'cs:cc:o:c' as 'cs:cc:o:c',	// Case System : Call Centre : Origins : CREATE
    cs_cc_o_d: 'cs:cc:o:d' as 'cs:cc:o:d',	// Case System : Call Centre : Origins : DELETE
    cs_cc_o_r: 'cs:cc:o:r' as 'cs:cc:o:r',	// Case System : Call Centre : Origins : READ
    cs_cc_o_u: 'cs:cc:o:u' as 'cs:cc:o:u',	// Case System : Call Centre : Origins : UPDATE
    d_ac: 'd:ac' as 'd:ac',	// Delete Account
    d_cr: 'd:cr' as 'd:cr',	// Delete Codification Request
    d_fs: 'd:fs' as 'd:fs',	// Delete FittingStation
    d_fs_ac: 'd:fs:ac' as 'd:fs:ac',	// Delete FS Capacity
    d_fs_ace: 'd:fs:ace' as 'd:fs:ace',	// Delete FS Capacity Exception
    d_fs_fss: 'd:fs:fss' as 'd:fs:fss',	// Delete FittingStation FittingStationService
    d_fs_jt: 'd:fs:jt' as 'd:fs:jt',	// Delete FS Job Type Configuration
    d_fs_oh: 'd:fs:oh' as 'd:fs:oh',	// Delete FittingStation Opening Hours
    d_fs_ph: 'd:fs:ph' as 'd:fs:ph',	// Delete FittingStation Photo
    d_fs_sa: 'd:fs:sa' as 'd:fs:sa',	// Delete FS Service Area
    d_fsg: 'd:fsg' as 'd:fsg',	// Delete FittingStationGroup
    d_fsg_ct: 'd:fsg:ct' as 'd:fsg:ct',	// Delete FittingStationGroup Contact
    do_djp: 'do:djp' as 'do:djp',	// Discount Override :: Dossier Job Page
    ds_d_docs: 'ds:d:docs' as 'ds:d:docs',	// Download ALL Dossier Documents
    ds_jb_ch_adv_qty: 'ds:jb:ch:adv:qty' as 'ds:jb:ch:adv:qty',	// Dossier Job Child Advised Quantity
    ds_ov_as: 'ds:ov:as' as 'ds:ov:as',	// Dossier overview appointment state
    mi_ac: 'mi:ac' as 'mi:ac',	// Menu Item Acocunts
    mi_ca: 'mi:ca' as 'mi:ca',	// Menu Item Agenda
    mi_cc: 'mi:cc' as 'mi:cc',	// Menu Item : Call Centre
    mi_cd: 'mi:cd' as 'mi:cd',	// Menu Item Central Departments
    mi_cr: 'mi:cr' as 'mi:cr',	// Menu Item Codification Request
    mi_cs: 'mi:cs' as 'mi:cs',	// Menu Item Counter Sale
    mi_cs_o: 'mi:cs:o' as 'mi:cs:o',	// Menu Item : Case System : Origins
    mi_cy: 'mi:cy' as 'mi:cy',	// Menu Item Countries
    mi_dac: 'mi:dac' as 'mi:dac',	// Menu Item Discount Approval Config
    mi_db: 'mi:db' as 'mi:db',	// Menu Item Dashboard
    mi_ds: 'mi:ds' as 'mi:ds',	// Menu Item Dossiers
    mi_ds_an_fs: 'mi:ds:an:fs' as 'mi:ds:an:fs',	// Additional Notes
    mi_ds_bi: 'mi:ds:bi' as 'mi:ds:bi',	// Menu Item Dossier Basic Info
    mi_ds_cap: 'mi:ds:cap' as 'mi:ds:cap',	// Menu Item Dossier Appointment
    mi_ds_col: 'mi:ds:col' as 'mi:ds:col',	// Menu Item Dossier Collaboration
    mi_ds_db: 'mi:ds:db' as 'mi:ds:db',	// Menu Item Dossier Dashboard
    mi_ds_dmg: 'mi:ds:dmg' as 'mi:ds:dmg',	// Menu Item Dossier Damage
    mi_ds_dp: 'mi:ds:dp' as 'mi:ds:dp',	// Menu Item Direct Purchasing
    mi_ds_dp_npo: 'mi:ds:dp:npo' as 'mi:ds:dp:npo',	// Dossier direct purchase non purchase order
    mi_ds_dp_po: 'mi:ds:dp:po' as 'mi:ds:dp:po',	// Dossier direct purchase purchase order
    mi_ds_dv_cd: 'mi:ds:dv:cd' as 'mi:ds:dv:cd',	// Menu Item Dossier Validation CD
    mi_ds_in: 'mi:ds:in' as 'mi:ds:in',	// Menu Item Dossier Instructions
    mi_ds_iv_cd: 'mi:ds:iv:cd' as 'mi:ds:iv:cd',	// Menu Item Dossier Invoicing CD
    mi_ds_iv_fs: 'mi:ds:iv:fs' as 'mi:ds:iv:fs',	// Menu Item Dossier Invoicing FS
    mi_ds_jb: 'mi:ds:jb' as 'mi:ds:jb',	// Menu Item Dossier Job
    mi_ds_jin: 'mi:ds:jin' as 'mi:ds:jin',	// Menu Item Dossier  Job Instructions
    mi_ds_jina: 'mi:ds:jina' as 'mi:ds:jina',	// Menu Item Dossier Job Instructions Activation
    mi_ds_tiv_cd: 'mi:ds:tiv:cd' as 'mi:ds:tiv:cd',	// menu item - dossier - tax invoice - central department
    mi_ds_tiv_fsg: 'mi:ds:tiv:fsg' as 'mi:ds:tiv:fsg',	// menu item - dossier - tax invoice - fitting and chain manager
    mi_dsc: 'mi:dsc' as 'mi:dsc',	// Menu Item Direct Sales
    mi_fsg: 'mi:fsg' as 'mi:fsg',	// Menu Item Fitting Station Groups
    mi_log: 'mi:log' as 'mi:log',	// Menu Item Logistics
    mi_msg: 'mi:msg' as 'mi:msg',	// Menu Item Message Center
    mi_nds: 'mi:nds' as 'mi:nds',	// Menu Item New Dossier
    mi_or: 'mi:or' as 'mi:or',	// Menu Item Organizations
    mi_quo: 'mi:quo' as 'mi:quo',	// Menu Item Quotations
    mi_sd: 'mi:sd' as 'mi:sd',	// Menu Item Send Documents
    mi_sic: 'mi:sic' as 'mi:sic',	// Menu Item Stock Inventory Control
    mi_sta: 'mi:sta' as 'mi:sta',	// Menu Item Send To Account
    mi_tax: 'mi:tax' as 'mi:tax',	// Menu Item Tax
    mi_tl: 'mi:tl' as 'mi:tl',	// Menu Item Translation Management
    mi_tp: 'mi:tp' as 'mi:tp',	// Menu Item Template Management
    p_vs: 'p:vs' as 'p:vs',	// Perform VIN Search Allowed
    r_ac: 'r:ac' as 'r:ac',	// Get Account
    r_cav: 'r:cav' as 'r:cav',	// Get Availability Calendar
    r_cd: 'r:cd' as 'r:cd',	// Get Central Department
    r_cr: 'r:cr' as 'r:cr',	// Get Codification Request
    r_cs: 'r:cs' as 'r:cs',	// Get Counter Sale
    r_cy: 'r:cy' as 'r:cy',	// Get Country
    r_dac: 'r:dac' as 'r:dac',	// Read Discount Approval Config
    r_ds: 'r:ds' as 'r:ds',	// Get Dossier
    r_ds_cm_cd: 'r:ds:cm:cd' as 'r:ds:cm:cd',	// Read Dossier Collaboration Message Central Department
    r_ds_ct_tmp: 'r:ds:ct:tmp' as 'r:ds:ct:tmp',	// Read :: Dossier :: CommunicationTab :: Templates
    r_ds_iv_cd: 'r:ds:iv:cd' as 'r:ds:iv:cd',	// Get Dossier Invoice CD
    r_ds_iv_fs: 'r:ds:iv:fs' as 'r:ds:iv:fs',	// Get Dossier Invoice FS
    r_dsc: 'r:dsc' as 'r:dsc',	// Get Direct Sale Cart
    r_erpiv: 'r:erpiv' as 'r:erpiv',	// Get Erp Invoice
    r_fl: 'r:fl' as 'r:fl',	// Get File
    r_fs: 'r:fs' as 'r:fs',	// Get FittingStation
    r_fs_ac: 'r:fs:ac' as 'r:fs:ac',	// Get FS Capacity
    r_fs_ace: 'r:fs:ace' as 'r:fs:ace',	// Get FS Capacity Exception
    r_fs_fss: 'r:fs:fss' as 'r:fs:fss',	// Get FittingStation FittingStationService
    r_fs_jt: 'r:fs:jt' as 'r:fs:jt',	// Read FS Job Type Configuration
    r_fs_ls: 'r:fs:ls' as 'r:fs:ls',	// Get Full FS list
    r_fs_oh: 'r:fs:oh' as 'r:fs:oh',	// Get FittingStation Opening Hours
    r_fs_ph: 'r:fs:ph' as 'r:fs:ph',	// Get FittingStation Photo
    r_fs_sa: 'r:fs:sa' as 'r:fs:sa',	// Read FS Service Area
    r_fsg: 'r:fsg' as 'r:fsg',	// Get FittingStationGroup
    r_fsg_ct: 'r:fsg:ct' as 'r:fsg:ct',	// Read FittingStationGroup Contact
    r_is: 'r:is' as 'r:is',	// Read Index Search
    r_is_cc: 'r:is:cc' as 'r:is:cc',	// Read Is Call Center
    r_lc: 'r:lc' as 'r:lc',	// Get Language Codes
    r_msg: 'r:msg' as 'r:msg',	// Get Messages
    r_nts: 'r:nts' as 'r:nts',	// Get Notes
    r_or: 'r:or' as 'r:or',	// Get Organization
    r_reqiv: 'r:reqiv' as 'r:reqiv',	// Get Request Invoice
    r_snd: 'r:snd' as 'r:snd',	// Read Send To Account
    r_tax: 'r:tax' as 'r:tax',	// Get Tax
    r_tp: 'r:tp' as 'r:tp',	// Get Template
    ru_ds_iv_cd_pf: 'ru:ds:iv:cd:pf' as 'ru:ds:iv:cd:pf',	// Dossier proforma
    rvsp_tax: 'rvsp:tax' as 'rvsp:tax',	// Reverse Tax Invoice Processed
    rvsup_tax: 'rvsup:tax' as 'rvsup:tax',	// Reverse Tax Invoice Unprocessed
    sg_cl: 'sg:cl' as 'sg:cl',	// Status group closed
    sg_dp: 'sg:dp' as 'sg:dp',	// Status group dossier preparation
    sg_fec: 'sg:fec' as 'sg:fec',	// Status group financial execution central claim management
    sg_fef: 'sg:fef' as 'sg:fef',	// Status group financial execution fitter
    sg_je: 'sg:je' as 'sg:je',	// Status group job execution
    u_a_cnf: 'u:a:cnf' as 'u:a:cnf',	// Update Agenda Confirm
    u_ac: 'u:ac' as 'u:ac',	// Update Account
    u_bfv: 'u:bfv' as 'u:bfv',	// Batch Financial Validation
    u_cr: 'u:cr' as 'u:cr',	// Update Codification Request
    u_cs: 'u:cs' as 'u:cs',	// Update Counter Sale
    u_dac: 'u:dac' as 'u:dac',	// Update Discount Approval Config
    u_djc: 'u:djc' as 'u:djc',	// Update Dossier Job Calculation
    u_ds_fsi_doc_oem: 'u:ds:fsi:doc:oem' as 'u:ds:fsi:doc:oem',	// Update Dossier FS invoice OEM Document
    u_ds_fsi_doc_or: 'u:ds:fsi:doc:or' as 'u:ds:fsi:doc:or',	// Update Dossier FS invoice Own Risk Document
    u_ds_jb_cld: 'u:ds:jb:cld' as 'u:ds:jb:cld',	// Update Dossier Job Calculation Date
    u_ds_jb_spo: 'u:ds:jb:spo' as 'u:ds:jb:spo',	// Update Job IsSpeedOrder
    u_dsfs: 'u:dsfs' as 'u:dsfs',	// Update Dossier FittingStation (Reassign)
    u_fl_rac: 'u:fl:rac' as 'u:fl:rac',	// User can update K4S file manually
    u_fs: 'u:fs' as 'u:fs',	// Update FittingStation
    u_fs_ac: 'u:fs:ac' as 'u:fs:ac',	// Update FS Capacity
    u_fs_ace: 'u:fs:ace' as 'u:fs:ace',	// Update FS Capacity Exception
    u_fs_fss: 'u:fs:fss' as 'u:fs:fss',	// Update FittingStation FittingStationService
    u_fs_oh: 'u:fs:oh' as 'u:fs:oh',	// Update FittingStation Opening Hours
    u_fs_ph: 'u:fs:ph' as 'u:fs:ph',	// Update FittingStation Photo
    u_fsg: 'u:fsg' as 'u:fsg',	// Update FittingStationGroup
    u_fsg_ct: 'u:fsg:ct' as 'u:fsg:ct',	// Update FittingStationGroup Contact
    u_tax: 'u:tax' as 'u:tax',	// Update Tax
    um_um: 'um:um' as 'um:um',	// Horizons Usermanamgement
    wf_cr_apr: 'wf:cr:apr' as 'wf:cr:apr',	// Workflow Codification Request Approve
    wf_cr_rej: 'wf:cr:rej' as 'wf:cr:rej',	// Workflow Codification Request Reject
    wf_cr_ret: 'wf:cr:ret' as 'wf:cr:ret',	// Workflow Codification Request Return
    wf_cr_sub: 'wf:cr:sub' as 'wf:cr:sub',	// Workflow Codification Request Submit
    mi_aai: 'mi:aai' as 'mi:aai', // Access the aari agenda menu item
    wf_ds_aai_fs: 'wf:ds:aai:fs' as 'wf:ds:aai:fs', // Access the aari "go to agenda" button in the dossier modify menu
    mi_ds_aai: 'mi:ds:aai' as 'mi:ds:aai' // Access to the aari agenda tab in the dossier modify menu

};

type Permission = (typeof Permission)[keyof typeof Permission];
export { Permission };
