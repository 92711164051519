import { zip as observableZip, Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Alert } from '@hrz/core/models/alert';
import { TranslateInitializeService } from '@hrz/core/services/translate-initialize.service';
import { UtilProxyService } from '@hrz/core/services/util-proxy.service';

@Component({
  selector: 'hrz-alert-message',
  templateUrl: './alert-message.component.html'
})
export class AlertMessageComponent implements OnInit, OnDestroy {
  currentTime: Date = new Date();
  filteredMessages: Alert[];
  locale: string;
  private _destroy$ = new Subject<void>();
  constructor(
    private utilproxyService: UtilProxyService,
    private translateInitializeService: TranslateInitializeService
  ) {}

  ngOnInit() {
    observableZip(this.utilproxyService.getAlerts(), this.translateInitializeService.getLanguageObservable(), (alerts, culureCode) => ({
      alerts,
      culureCode,
    }))
      .pipe(takeUntil(this._destroy$))
      .subscribe(objects => {
        this.locale = objects.culureCode;
        const currentTime: Date = new Date();
        const alertMessages: Alert[] = objects.alerts.filter(
          a => new Date(a.StartDate) <= currentTime && new Date(a.EndDate) >= currentTime
        );
        this.filteredMessages = alertMessages;
      });
  }
  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
