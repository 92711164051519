import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component } from '@angular/core';
import { AuthService } from '@hrz/core/services/auth.service';

@Component({
  templateUrl: './logout.modal.html',
})

export class LogoutModalComponent {

  constructor(
    public bsModalRef: BsModalRef,
    private authService: AuthService
  ) {}

  clickCancel(): void {
    this.bsModalRef.hide();
  }

  clickOk(): void {
    this.authService.logout();
    this.bsModalRef.hide();
  }
}
