import { Injectable, Optional, SkipSelf } from '@angular/core';

import { Subject, Observable } from 'rxjs';

import { isPresent } from '../utils/slim-loading-bar.utils';
import { AppInsightsService } from './app-insights.service';

export enum SlimLoadingBarEventType {
    PROGRESS,
    HEIGHT,
    COLOR,
    VISIBLE
}

export class SlimLoadingBarEvent {
    constructor(public type: SlimLoadingBarEventType, public value: any) {}
}

@Injectable({
    providedIn: 'root',
})
export class SlimLoadingBarService {

    private _progress = 0;
    private _height = '2px';
    private _color = 'firebrick';
    private _visible = true;

    private _intervalCounterId: any = 0;
    public interval = 500; // in milliseconds

    private eventSource: Subject<SlimLoadingBarEvent> = new Subject<SlimLoadingBarEvent>();
    public events: Observable<SlimLoadingBarEvent> = this.eventSource.asObservable();

    constructor(
        appInsightsService: AppInsightsService,
        @Optional() @SkipSelf() parent?: SlimLoadingBarService
      ) {
        // Enforces this service to be loaded as a singleton
        if (parent) {
          appInsightsService.logException(new Error('SlimLoadingBarService is a Singleton and should only be loaded in AppModule.'));
        }
      }


    set progress(value: number) {
        if (isPresent(value)) {
            if (value > 0) {
                this.visible = true;
            }
            this._progress = value;
            this.emitEvent(new SlimLoadingBarEvent(SlimLoadingBarEventType.PROGRESS, this._progress));
        }
    }

    get progress(): number {
        return this._progress;
    }


    set height(value: string) {
        if (isPresent(value)) {
            this._height = value;
            this.emitEvent(new SlimLoadingBarEvent(SlimLoadingBarEventType.HEIGHT, this._height));
        }
    }

    get height(): string {
        return this._height;
    }

    set color(value: string) {
        if (isPresent(value)) {
            this._color = value;
            this.emitEvent(new SlimLoadingBarEvent(SlimLoadingBarEventType.COLOR, this._color));
        }
    }

    get color(): string {
        return this._color;
    }

    set visible(value: boolean) {
        if (isPresent(value)) {
            this._visible = value;
            this.emitEvent(new SlimLoadingBarEvent(SlimLoadingBarEventType.VISIBLE, this._visible));
        }
    }

    get visible(): boolean {
        return this._visible;
    }

    private emitEvent(event: SlimLoadingBarEvent) {
        if (this.eventSource) {
            // Push up a new event
            this.eventSource.next(event);
        }
    }


    start(onCompleted: Function = null) {
        // Stop current timer
        this.stop();
        // Make it visible for sure
        this.visible = true;
        // Run the timer with milliseconds iterval
        this._intervalCounterId = setInterval(() => {
            // Increment the progress and update view component
            this.progress++;
            // If the progress is 100% - call complete
            if (this.progress === 100) {
                this.complete(onCompleted);
            }
        }, this.interval);
    }

    stop() {
        if (this._intervalCounterId) {
            clearInterval(this._intervalCounterId);
            this._intervalCounterId = null;
        }
    }

    reset() {
        this.stop();
        this.progress = 0;
    }

    complete(onCompleted: Function = null) {
        this.progress = 100;
        this.stop();
        setTimeout(() => {
            // Hide it away
            this.visible = false;
            setTimeout(() => {
                // Drop to 0
                this.progress = 0;
                if (onCompleted) {
                    onCompleted();
                }
            }, 250);
        }, 250);
    }
}
