import { catchError } from 'rxjs/operators';
import { DropdownDefinition } from '@hrz/core/models/multilangual/dropdown-definition';
import { Observable } from 'rxjs';
import { ConfigurationManager } from '@hrz/core/models/configuration.manager';
import { Injectable, Optional, SkipSelf } from '@angular/core';
import { ServiceActionHandler } from '@hrz/core/services/service-action-handler';
import { SmartDropdown } from '@hrz/core/models/smart-dropdown';
import { ApiActionResult } from '@hrz/core/models/api-action-result';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class DropdownDefinitionService {
  private _httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
  private _apiUrl = `${ConfigurationManager.AppSettings.multilangualManagementApi}/dropdown-definitions`;

constructor(
    private http: HttpClient,
    private serviceActionHandler: ServiceActionHandler,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: DropdownDefinitionService
  ) {
    if (parent) {
      appInsightsService.logException(new Error('DropdownDefinitionService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  public listSimple(): Observable<DropdownDefinition[]> {
    return this.http.get<DropdownDefinition[]>(this._apiUrl + '/dropdowns-only', { headers: this._httpHeaders }).pipe(
      catchError(this.serviceActionHandler.handleDefaultError)
    );
  }

  public list(): Observable<DropdownDefinition[]> {
    return this.http.get<DropdownDefinition[]>(this._apiUrl, { headers: this._httpHeaders }).pipe(
      catchError(this.serviceActionHandler.handleDefaultError)
    );
  }

  public promisedGet(id: string): Promise<DropdownDefinition> {
    return this.http
      .get<DropdownDefinition>(`${this._apiUrl}/${id}`, { headers: this._httpHeaders })
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public get(id: string): Observable<DropdownDefinition> {
    return this.http.get<DropdownDefinition>(`${this._apiUrl}/${id}`, { headers: this._httpHeaders }).pipe(
      catchError(this.serviceActionHandler.handleDefaultError)
    );
  }

  public update(id: string, item: DropdownDefinition): Promise<ApiActionResult> {
    return this.http
      .put(`${this._apiUrl}/${id}`, item, { headers: this._httpHeaders })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public create(item: DropdownDefinition): Promise<ApiActionResult> {
    return this.http
      .post(`${this._apiUrl}`, item, { headers: this._httpHeaders })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public getByName(name: string): Observable<DropdownDefinition> {
    return this.http.get<DropdownDefinition>(`${this._apiUrl}/get-by-name/${name}`, { headers: this._httpHeaders }).pipe(
      catchError(this.serviceActionHandler.handleDefaultError)
    );
  }

  public exportModules(exportForLanguage: string, filterDropdowns: string[]): Promise<SmartDropdown[]> {
    let filterQuery = '';
    if (filterDropdowns) {
      filterQuery = '&filterModules=';
      for (let i = 0; i <= filterDropdowns.length - 1; i++) {
        filterQuery = filterQuery + filterDropdowns[i] + ';';
      }
    }
    const completeUrl = this._apiUrl + '/export?languages=en-GB;' + exportForLanguage + filterQuery;
    console.log('DropdownDefinitionService.export()', completeUrl);
    return this.http
      .get<SmartDropdown[]> (completeUrl, { headers: this._httpHeaders })
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public saveTranslations(items: SmartDropdown[]): Promise<ApiActionResult> {
    console.log('DropdownDefinitionService.saveTranslations()');

    console.log('POST - JSON Command: ', JSON.stringify(items));
    const completeUrl = this._apiUrl + '/saveorupdate';
    return this.http
      .post(completeUrl, JSON.stringify(items), { headers: this._httpHeaders })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }
}
