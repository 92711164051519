import { of, Observable } from 'rxjs';

import { timeout, retry } from 'rxjs/operators';
import { Injectable, Optional, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { ConfigurationManager } from '@hrz/core/models/configuration.manager';
import { ServiceActionHandler } from '@hrz/core/services/service-action-handler';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class TranslateInitializeService {
  private _translations: any;
  private apiUrl = ConfigurationManager.AppSettings.multilangualManagementApi + '/translation-languages/module-translation?cultureCode=';

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private serviceActionHandler: ServiceActionHandler,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: TranslateInitializeService
    ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('TranslateInitializeService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  get translations(): any {
    return this._translations;
  }

  public setAppTranslations(): Promise<any> {
    return this.getLanguageObservable()
      .toPromise()
      .then(lang => {
        this._translations = null;

        return this.getTranslation(lang)
          .then(response => {
            this.translate.setDefaultLang(lang);
            this.translate.setTranslation(lang, response);
            this._translations = response;
          })
          .catch(this.serviceActionHandler.handleDefaultError);
      });
  }

  public setLanguage(languageCode: string): Observable<boolean> {
    localStorage.setItem('language_code', languageCode);
    return of(true);
  }

  public getLanguageObservable(): Observable<string> {
    return of(TranslateInitializeService.getLanguage());
  }

  public static getLanguage(): string {
    return localStorage.getItem('language_code') || 'en-GB';
  }

  private getTranslation(langCode: string): Promise<any> {
    return this.http.get(`${this.apiUrl}${langCode}`).pipe(retry(3), timeout(10000)).toPromise();
  }
}
