<div class="modal-header">
    <h4 class="modal-title">{{'SHARED.MODALS.LOGOUT_CONFIRMATION' | translate}}</h4>
    <button type="button" class="close" (click)="clickCancel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>{{'SHARED.MODALS.ARE_YOU_SURE_TO_LOGOUT' | translate}}</p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="clickCancel()">{{'COMMON.NO' | translate}}</button>
    <button type="button" class="btn btn-primary" (click)="clickOk()">{{'COMMON.YES_I_AM_SURE' | translate}}</button>
</div>