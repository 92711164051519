export enum ProtocolFlowEnum {
  NotSet,
  Sending,
  Authorization,
  Validation,
  RetrieveIcUpdates,
  GenerateAuthorizationFile,
  Cancellation,
  IcSpecificDocumentAtCheckin,
  IcSpecificDocumentAtCheckout,
  SendIcMessage,
  ContentValidation,
  CustomAction,
  IcView,
  Ngc,
  ProForma,
  VatDivert,
  Quote, // Used for NGC Quotevalidations (for now).
  PolicyQuery,
  InvoiceGeneration,
  ReceiveIcCase,
  SendIcPreparation,
  SendIcUpdate,
  ResponseAction,
  AdasReportValidation,
  AppGeneratedDocument,
}
