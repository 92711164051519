import { Component, OnInit } from '@angular/core';
import { SignalrDossierService } from './core/services/signalr.dossier.service';
import { NavigationEnd, Router } from '@angular/router';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';
import { AuthService } from '@hrz/core/services/auth.service';

declare const gtag: Function;

@Component({
  selector: 'hrz-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {

  constructor(
    private signalrDossierService: SignalrDossierService,
    public authService: AuthService,
    router: Router,
    appInsightsService: AppInsightsService
  ) {
    appInsightsService.logTrace('AppComponent constructor');

    this.signalrDossierService.error$.subscribe(
      (error: any) => {
        console.warn(error);
      },
      (error: any) => {
        console.error('errors$ error', error);
      }
    );
    this.signalrDossierService.starting$.subscribe(
      () => {
        console.log('AppComponent.constructor() SignalR: service has been started');
      },
      () => {
        console.warn('AppComponent.constructor() SignalR: service failed to start!');
      }
    );
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('event', 'page_view', {
          page_hostname: window.location.hostname,
          page_path: event.urlAfterRedirects,
          page_location: window.location.origin + event.urlAfterRedirects,
        });
      }
    });
  }

  ngOnInit() {
    this.signalrDossierService.start();
  }


}
