import { Injectable, Optional, SkipSelf } from '@angular/core';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

function _window(): Window {
  return window;
}
@Injectable({
  providedIn: 'root',
})
export class WindowService {
  constructor(
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: WindowService
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('WindowService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  get nativeWindow(): Window {
    return _window();
  }
}
