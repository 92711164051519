import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';
import { AuthService } from '@hrz/core/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: PermissionGuard
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('PermissionGuard is a Singleton and should only be loaded in AppModule.'));
    }
    console.log('PermissionGuard.Constructor()');
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    console.log('PermissionGuard.canActivate()');

    if (!this.auth.isAuthenticated()) {
      return true; // Erkan Ceyhan, we need to find a better approach....
    }

    if (route.data) {
      // Are we checking for one permission?
      if (route.data.requiredPermission) {
        const role = route.data.requiredPermission;
        const hasAccess = this.auth.userHasScope(role);
        console.log('PermissionGuard: Guarding route', role, hasAccess);

        if (!hasAccess && route.data.fallback) {
          this.router.navigate([route.data.fallback]);
        }

        return hasAccess;
      }
      // Are we checking for one or more permission(s)?
      if (route.data.requiredPermissions) {
        const roles = route.data.requiredPermissions;
        const hasAccess = this.auth.userHasScopes(roles);
        console.log('PermissionGuard: Guarding route', roles, hasAccess);
        return hasAccess;
      }
    }
    // Return "allow" by default
    return true;
  }
  canActivateChild(route: ActivatedRouteSnapshot): boolean {

    console.log('PermissionGuard.canActivateChild()');
    return this.canActivate(route);

  }
}
