<div class="btn-group user-profile" dropdown placement="top">

  <div class="user-initials" dropdownToggle>
    <hrz-user-initials></hrz-user-initials>
  </div>

  <ul *dropdownMenu class="dropdown-menu" role="menu">
    <div class="title-wrapper">
      <hrz-user-initials></hrz-user-initials>
    </div>
    <li role="menuitem" class="title">
      <div class="text"> {{'SHARED.GREETING' | translate}} {{username}}</div>
    </li>
    <li class="divider dropdown-divider"></li>
    <li role="menuitem" class="time-zone">
      [Zone: {{zoneName}} - {{timeZone}}]
    </li>
    <li class="divider dropdown-divider"></li>
    <li role="menuitem">
      <div class="form-group dropdown-item">
        <form [formGroup]="userProfileForm">
          <label for="language">{{ 'SHARED.LANGUAGE' | translate }}:</label>
          <select id="language" name="language" class="form-control" formControlName="language" [compareWith]="languageCompare">
            <option [ngValue]="null" selected="selected" disabled>{{ 'SHARED.LANGUAGE' | translate }}</option>
            <option *ngFor="let item of languages" [ngValue]="item">{{item.name}}</option>
          </select>
        </form>
      </div>
    </li>
    <li class="divider dropdown-divider"></li>
    <li role="menuitem">
      <a class="dropdown-item" (click)="logout()">
        {{'SHARED.AUTH.LOGOUT' | translate}}
        <i class="fa fa-chevron-right icon pull-right" aria-hidden="true"></i>
      </a>
    </li>
  </ul>
</div>