import { Pipe, PipeTransform } from '@angular/core';
import { ValidationProtocolContentValidation } from '@hrz/core/models/validation-protocol-content-validation';
import { TranslateService } from '@ngx-translate/core';

/*
SelectFieldRegexPipe,  this pipe can be used to extract the regex pattern for a field content validation
The input for the pipe is a collection of ContentFieldValidation settings

Usage example: <input pattern="{{ contentValidations | contentValidationRegex: 'PolicyNumber' }}" ...>
*/

@Pipe({ name: 'contentValidationRegex' })
export class SelectFieldRegexPipe implements PipeTransform {
  transform(value: ValidationProtocolContentValidation[], fieldName: string): string {
    if (value) {
      const validation = value.find(v => v.FieldName === fieldName);

      if (validation) { return validation.Regex; }
    }

    return '';
  }
}

/*
SelectFieldFormatPipe,  this pipe can be used to get the translated error message for a field content validation
The input for the pipe is a collection of ContentFieldValidation settings

Usage example: <div class="error" *ngIf="icClaimNumber.errors">Message: {{ contentValidations | contentValidationMessage: 'IcClaimNumber' }}</div>
*/
@Pipe({ name: 'contentValidationMessage' })
export class SelectFieldFormatPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: ValidationProtocolContentValidation[], fieldName: string) {
    if (value) {
      const validation = value.find(v => v.FieldName === fieldName);

      if (validation) { return this.translateErrorMessage(validation.TranslationKey); }
    }

    return '';
  }

  private translateErrorMessage(message: string): string {
    // A returned error message can contain a message, a translation key or a translation key with additional text that should not be translated
    // In the last case the format is TRANSLATION_KEY<<additional message>>
    const regex = /(.*)<<(.*)>>/g;

    const matches = regex.exec(message);

    if (matches == null || matches.length != 3) { return this.translateService.instant(message); }

    return `${this.translateService.instant(matches[1])} ${matches[2]}`;
  }
}
