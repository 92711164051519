<div *ngIf="isDisplayed">
  <span class="switch-text" [ngClass]="{'checked': !isNewVersion}">
    <img class="switch-img" [src]="!isNewVersion ? srcImgDark : srcImgLight">
    Old Version
  </span>

  <label class="switch">
    <input type="checkbox" (change)="onToggleChange()" [checked]="isNewVersion">
    <span class="slider round"></span>
  </label>

  <span class="switch-text" [ngClass]="{'checked': isNewVersion}">
    <img class="switch-img" [src]="isNewVersion ? srcImgDark : srcImgLight">
    New Version
  </span>
</div>
