import { Address } from '@hrz/core/models/address';
import { Organization } from '@hrz/core/models/organization';
import { CentralDepartment } from '@hrz/core/models/central-department';

export class LegalEntity {
  Id: number;
  Name: string;
  Currency: string;
  Vat: string;
  ChamberOfCommerce: string;
  Iban: string;
  OrganisationDto: Organization;
  CentralDepartmentDto: CentralDepartment;
  PostalAddressDto: Address;
  BusinessAddressDto: Address;
  ErpIdentifier: string;
  GmbAccountResource: string;
  IsConsignment: boolean;
  LegalEntitySettingsDto: LegalEntitySettings;
}

class LegalEntitySettings {
  AllowedNoticationType: string[];
  PreferredCalibrationReason: string[];
  EnabledCustomAuthorization: boolean;
}
